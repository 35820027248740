<template>
  <el-drawer :visible.sync="params.show"  :before-close="close" size="50%" :modal="false" :with-header="false" @open="openDrawer">
    <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="封面" prop="cover_path">
        <image-upload v-model="ruleForm.cover_path"></image-upload>
      </el-form-item>
      <el-form-item label="路由地址" prop="route">
        <el-select v-model="ruleForm.route" placeholder="请选择">
          <el-option
            v-for="item in routeList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题(中文)" prop="title_cn">
        <el-input v-model="ruleForm.title_cn"></el-input>
      </el-form-item>
      <el-form-item label="标题(英文)" prop="title_us">
        <el-input v-model="ruleForm.title_us"></el-input>
      </el-form-item>
      <el-form-item label="副标题(中文)" prop="subhead_cn">
        <el-input v-model="ruleForm.subhead_cn"></el-input>
      </el-form-item>
      <el-form-item label="副标题(英文)" prop="subhead_us">
        <el-input v-model="ruleForm.subhead_us"></el-input>
      </el-form-item>

      <el-form-item label="介绍(中文)" prop="introduce_cn">
        <el-input type="textarea" v-model="ruleForm.introduce_cn" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>
      <el-form-item label="介绍(英文)" prop="introduce_us">
        <el-input type="textarea" v-model="ruleForm.introduce_us" :autosize="{ minRows: 6, maxRows: 4}"></el-input>
      </el-form-item>

      <el-form ref="bannerRef" :model="ruleForm.document.banner" :rules="itemRules" label-width="120px">
          <el-form-item label="详情页banner图" prop="image_path">
            <image-upload v-model="ruleForm.document.banner.image_path"></image-upload>
          </el-form-item>
          <el-form-item label="详情页路由地址" prop="route">
            <el-input v-model="ruleForm.document.banner.route" placeholder="请输入路由地址"></el-input>
          </el-form-item>
          <el-form-item label="详情页路由名称(中文)" prop="route_name_cn">
            <el-input v-model="ruleForm.document.banner.route_name_cn" placeholder="请输入路由名称(中文)"></el-input>
          </el-form-item>
          <el-form-item label="详情页路由名称(英文)" prop="route_name_us">
            <el-input v-model="ruleForm.document.banner.route_name_us" placeholder="请输入路由名称(英文)"></el-input>
          </el-form-item>
        </el-form>

      <div class="item-form-box">
        <h4>表单一</h4>
        <el-form ref="ruleFormOneRef" :model="ruleForm.document.form_one" :rules="itemRules" label-width="120px">
          <el-form-item label="标题(中文)" prop="title_cn">
            <el-input v-model="ruleForm.document.form_one.title_cn" placeholder="请输入标题(中文)"></el-input>
          </el-form-item>
          <el-form-item label="标题(英文)" prop="title_us">
            <el-input v-model="ruleForm.document.form_one.title_us" placeholder="请输入标题(英文)"></el-input>
          </el-form-item>
          <el-form-item label="描述(中文)" prop="describe_cn">
            <el-input type="textarea" v-model="ruleForm.document.form_one.describe_cn" placeholder="请输入描述(中文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="描述(英文)" prop="describe_us">
            <el-input type="textarea" v-model="ruleForm.document.form_one.describe_us" placeholder="请输入描述(英文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <div class="icon-view" v-for="(item, index) in ruleForm.document.form_one.children" :key="index">
            <h5>表单一 图标{{ index + 1 }} <el-link type="danger" @click="delChildren('form_one', index)">删除</el-link></h5>
            <el-form :ref="'ruleFormOneIconRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <div>
                <el-form-item label="图标" prop="icon_path">
                  <image-upload v-model="item.icon_path"></image-upload>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="图标标题(中文)" prop="title_cn">
                  <el-input v-model="item.title_cn" placeholder="请输入图标标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="图标标题(英文)" prop="title_us">
                  <el-input v-model="item.title_us" placeholder="请输入图标标题(英文)"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-link type="primary" @click="addChildren('form_one')">添加图标</el-link>
        </el-form>
      </div>

      <div class="item-form-box">
        <h4>表单二</h4>
        <el-form ref="ruleFormTwoRef" :model="ruleForm.document.form_two" :rules="itemRules" label-width="120px">
          <el-form-item label="背景图" prop="background_path">
            <image-upload v-model="ruleForm.document.form_two.background_path"></image-upload>
          </el-form-item>
          <el-form-item label="标题(中文)" prop="title_cn">
            <el-input v-model="ruleForm.document.form_two.title_cn" placeholder="请输入标题(中文)"></el-input>
          </el-form-item>
          <el-form-item label="标题(英文)" prop="title_us">
            <el-input v-model="ruleForm.document.form_two.title_us" placeholder="请输入标题(英文)"></el-input>
          </el-form-item>
          <el-form-item label="描述(中文)" prop="describe_cn">
            <el-input type="textarea" v-model="ruleForm.document.form_two.describe_cn" placeholder="请输入描述(中文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="描述(英文)" prop="describe_us">
            <el-input type="textarea" v-model="ruleForm.document.form_two.describe_us" placeholder="请输入描述(英文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <div class="icon-view" v-for="(item, index) in ruleForm.document.form_two.children" :key="index">
            <h5>表单二 图标{{ index + 1 }} <el-link type="danger" @click="delChildren('form_two', index)">删除</el-link></h5>
            <el-form :ref="'ruleFormOneIconRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <div>
                <el-form-item label="图标" prop="icon_path">
                  <image-upload v-model="item.icon_path"></image-upload>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="图标标题(中文)" prop="title_cn">
                  <el-input v-model="item.title_cn" placeholder="请输入图标标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="图标标题(英文)" prop="title_us">
                  <el-input v-model="item.title_us" placeholder="请输入图标标题(英文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(中文)" prop="describe_cn">
                  <el-input v-model="item.describe_cn" placeholder="请输入图标副标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(英文)" prop="describe_us">
                  <el-input v-model="item.describe_us" placeholder="请输入图标副标题(英文)"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-link type="primary" @click="addChildren('form_two')">添加图标</el-link>
          <el-form-item label="底部标题(中文)" prop="foot_describe_cn">
            <el-input v-model="ruleForm.document.form_two.foot_describe_cn" placeholder="请输入底部标题(中文)"></el-input>
          </el-form-item>
          <el-form-item label="底部标题(英文)" prop="foot_describe_us">
            <el-input v-model="ruleForm.document.form_two.foot_describe_us" placeholder="请输入底部标题(英文)"></el-input>
          </el-form-item>
          <el-form-item label="路由地址" prop="route">
            <el-input v-model="ruleForm.document.form_two.route" placeholder="请输入路由地址"></el-input>
          </el-form-item>
          <el-form-item label="路由名称(中文)" prop="route_name_cn">
            <el-input v-model="ruleForm.document.form_two.route_name_cn" placeholder="请输入路由名称(中文)"></el-input>
          </el-form-item>
          <el-form-item label="路由名称(英文)" prop="route_name_us">
            <el-input v-model="ruleForm.document.form_two.route_name_us" placeholder="请输入路由名称(英文)"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="item-form-box">
        <h4>表单三</h4>
        <el-form ref="ruleFormThreeRef" :model="ruleForm.document.form_three" :rules="itemRules" label-width="120px">
          <el-form-item label="背景图" prop="background_path">
            <image-upload v-model="ruleForm.document.form_three.background_path"></image-upload>
          </el-form-item>
          <el-form-item label="标题(中文)" prop="title_cn">
            <el-input v-model="ruleForm.document.form_three.title_cn" placeholder="请输入标题(中文)"></el-input>
          </el-form-item>
          <el-form-item label="标题(英文)" prop="title_us">
            <el-input v-model="ruleForm.document.form_three.title_us" placeholder="请输入标题(英文)"></el-input>
          </el-form-item>
          <el-form-item label="描述(中文)" prop="describe_cn">
            <el-input type="textarea" v-model="ruleForm.document.form_three.describe_cn" placeholder="请输入描述(中文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="描述(英文)" prop="describe_us">
            <el-input type="textarea" v-model="ruleForm.document.form_three.describe_us" placeholder="请输入描述(英文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <div class="icon-view" v-for="(item, index) in ruleForm.document.form_three.children" :key="index">
            <h5>表单三 图标{{ index + 1 }} <el-link type="danger" @click="delChildren('form_three', index)">删除</el-link></h5>
            <el-form :ref="'ruleFormOneIconRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <div>
                <el-form-item label="图标" prop="icon_path">
                  <image-upload v-model="item.icon_path"></image-upload>
                </el-form-item>
              </div>
              <div>
                <el-form-item label="图标标题(中文)" prop="title_cn">
                  <el-input v-model="item.title_cn" placeholder="请输入图标标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="图标标题(英文)" prop="title_us">
                  <el-input v-model="item.title_us" placeholder="请输入图标标题(英文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(中文)" prop="describe_cn">
                  <el-input v-model="item.describe_cn" placeholder="请输入图标副标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(英文)" prop="describe_us">
                  <el-input v-model="item.describe_us" placeholder="请输入图标副标题(英文)"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-link type="primary" @click="addChildren('form_three')">添加图标</el-link>
          <el-form-item label="路由地址" prop="route">
            <el-input v-model="ruleForm.document.form_three.route" placeholder="请输入路由地址"></el-input>
          </el-form-item>
          <el-form-item label="路由名称(中文)" prop="route_name_cn">
            <el-input v-model="ruleForm.document.form_three.route_name_cn" placeholder="请输入路由名称(中文)"></el-input>
          </el-form-item>
          <el-form-item label="路由名称(英文)" prop="route_name_us">
            <el-input v-model="ruleForm.document.form_three.route_name_us" placeholder="请输入路由名称(英文)"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div class="item-form-box">
        <h4>表单四</h4>
        <el-form ref="ruleFormThreeRef" :model="ruleForm.document.form_four" :rules="itemRules" label-width="120px">
          <el-form-item label="背景图" prop="background_path">
            <image-upload v-model="ruleForm.document.form_four.background_path"></image-upload>
          </el-form-item>
          <el-form-item label="标题(中文)" prop="title_cn">
            <el-input v-model="ruleForm.document.form_four.title_cn" placeholder="请输入标题(中文)"></el-input>
          </el-form-item>
          <el-form-item label="标题(英文)" prop="title_us">
            <el-input v-model="ruleForm.document.form_four.title_us" placeholder="请输入标题(英文)"></el-input>
          </el-form-item>
          <el-form-item label="描述(中文)" prop="describe_cn">
            <el-input type="textarea" v-model="ruleForm.document.form_four.describe_cn" placeholder="请输入描述(中文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <el-form-item label="描述(英文)" prop="describe_us">
            <el-input type="textarea" v-model="ruleForm.document.form_four.describe_us" placeholder="请输入描述(英文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
          </el-form-item>
          <div v-for="(item, index) in ruleForm.document.form_four.children" :key="index">
            <h5>表单四 段落{{ index + 1 }} <el-link type="danger" @click="delChildren('form_four', index)">删除</el-link></h5>
            <el-form :ref="'ruleFormOneIconRef' + index" :model="item" :rules="itemRules" label-width="120px">
              <div>
                <el-form-item label="图标标题(中文)" prop="title_cn">
                  <el-input v-model="item.title_cn" placeholder="请输入标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="图标标题(英文)" prop="title_us">
                  <el-input v-model="item.title_us" placeholder="请输入标题(英文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(中文)" prop="subhead_cn">
                  <el-input v-model="item.subhead_cn" placeholder="请输入副标题(中文)"></el-input>
                </el-form-item>
                <el-form-item label="副标题(英文)" prop="subhead_us">
                  <el-input v-model="item.subhead_us" placeholder="请输入副标题(英文)"></el-input>
                </el-form-item>
                <el-form-item label="描述(中文)" prop="describe_cn">
                  <el-input type="textarea" v-model="item.describe_cn" placeholder="请输入描述(中文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
                </el-form-item>
                <el-form-item label="描述(英文)" prop="describe_us">
                  <el-input type="textarea" v-model="item.describe_us" placeholder="请输入描述(英文)" :autosize="{ minRows: 4, maxRows: 8}"></el-input>
                </el-form-item>
                <el-form-item label="路由地址" prop="route">
                  <el-input v-model="item.route" placeholder="请输入路由地址"></el-input>
                </el-form-item>
                <el-form-item label="路由名称(中文)" prop="route_name_cn">
                  <el-input v-model="item.route_name_cn" placeholder="请输入路由名称(中文)"></el-input>
                </el-form-item>
                <el-form-item label="路由名称(英文)" prop="route_name_us">
                  <el-input v-model="item.route_name_us" placeholder="请输入路由名称(英文)"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <el-link type="primary" @click="addChildren('form_four')">添加段落</el-link>
        </el-form>
      </div>

      <el-form-item>
        <el-button type="primary" @click="submit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>


    </el-form>
  </el-drawer>
</template>
<script>
import { editIndustryInfo, addIndustryInfo } from '@/api/industry/industry'
import imageUpload from '@/components/imageupload/image-upload.vue'
export default {
  components: {
    imageUpload
  },
  data() {
    return {
      routeList: [
        {
          label: '行业模板一',
          value: '/industry/v1'
        },
      ],
      ruleFormTemp: {
        id: null,
        title_cn: '',
        title_us: '',
        subhead_cn: '',
        subhead_us: '',
        cover_path: '',
        introduce_cn: '',
        introduce_us: '',
        route: '',
        document: {
          banner: {
            image_path: '',
            route: '',
            route_name_us: '',
            route_name_cn: ''
          },
          form_one: {
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            children: []
          },
          form_two: {
            background_path: '',
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            children: [],
            foot_describe_cn: '',
            foot_describe_us: '',
            route: '',
            route_name_us: '',
            route_name_cn: ''
          },
          form_three: {
            background_path: '',
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            children: [],
            route: '',
            route_name_us: '',
            route_name_cn: ''
          },
          form_four: {
            background_path: '',
            title_cn: '',
            title_us: '',
            describe_cn: '',
            describe_us: '',
            children: []
          }
        }
      },
      ruleForm: {},
      rules: {
          cover_path: [
            { required: true, message: '请上传封面', trigger: 'blur' },
          ],
          title_cn: [
            { required: true, message: '请输入路由地址', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
          ],
          title_cn: [
            { required: true, message: '请输入标题(中文)', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
          ],
          title_us: [
            { required: true, message: '请输入标题(英文)', trigger: 'blur' },
            { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
          ],
          subhead_cn: [
            { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          subhead_us: [
            { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' },
          ],
          route: [
            { required: true, message: '请选择路由模板', trigger: 'blur' },
          ]
      },
      itemRules: {
        title_cn: [
          { required: true, message: '请输入路由地址', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 60 个字符', trigger: 'blur' },
        ],
        title_us: [
          { required: true, message: '请输入标题(中文)', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
        ],
        image_path: [
          { required: true, message: '请输上传banner图', trigger: 'blur' },
        ],
      }
    }
  },
  props: {
    params: {
      required: true,
      type: Object,
    }
  },
  mounted () {
    this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormTemp))
  },
  methods: {
    close() {
      this.$refs.ruleFormRef.resetFields()
      this.ruleForm = JSON.parse(JSON.stringify(this.ruleFormTemp))
      this.$emit('close')
    },
    addChildren (type) {
      const config = {
        form_one: {
          icon_path: '',
          title_cn: '',
       		title_us: '',
        },
        form_two: {
          icon_path: '',
          title_cn: '',
       		title_us: '',
          describe_cn: '',
        	describe_us: '',
        },
        form_three: {
          icon_path: '',
          title_cn: '',
       		title_us: '',
          describe_cn: '',
        	describe_us: '',
        },
        form_four: {
          title_cn: '',
       		title_us: '',
          subhead_cn: '',
          subhead_us: '',
          describe_cn: '',
        	describe_us: '',
          route: '',
          route_name_us: '',
          route_name_cn: ''
        }
      }
      this.ruleForm.document[type].children.push(JSON.parse(JSON.stringify(config[type])))
    },
    delChildren (type, index) {
      this.ruleForm.document[type].children.splice(index, 1)
    },
    openDrawer () {
      if (this.params.data) {
        this.ruleForm = this.params.data
      }
      if (!this.ruleForm.document) {
        this.ruleForm.document = JSON.parse(JSON.stringify(this.ruleFormTemp.document))
      }
    },
    async submit() {
      this.$refs.ruleFormRef.validate(async (v) => {
        if (!v) return
        const result = this.params.data ? await editIndustryInfo(this.ruleForm) : await addIndustryInfo(this.ruleForm)
        if (result.success) {
          this.$message.success('已完成')
          this.close()
        }
      })
    },
  },
  computed: {
  },
}
</script>
<style scoped lang="less">
  .el-form {
    margin: 10px;
  }
  .item-form-box {
    padding-bottom: 30px;
    border-top: 1px dashed #999;
  }
  .icon-view {
    .el-form {
      >div {
        width: 45%;
        margin-right: 20px;
        display: inline-block;
        vertical-align: top;
        .el-form-item {

        }
      }
    }
  }
</style>
